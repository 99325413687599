import styled from 'styled-components'
import { Card } from '../../../../styled/cards'

export const ContactContainer = styled.div`
    display: flex;
    max-width: 70rem;
    padding-bottom: 4rem;
    overflow-x: hidden;
    position: relative;
`

export const ContactCard = styled(Card)`
    padding: 1.8rem;
    width: 22rem;
    border-radius: 1rem !important;
`

export const ChooseContactWrapper = styled.div`
    display: flex;
    transition: transform 0.3s ease-in-out;
    gap: 2rem;
`

export const Flex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
`

export const AvatarImage = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
`

export const ContactHeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const ContactHeading = styled.h3`
    font-size: 1.8rem;
    font-weight: 700;
`

export const ContactPosition = styled.h4`
    font-size: 1.125rem;
`

export const LanguagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

export const GreyFont = styled.h4`
    font-size: 1.125rem;
    color: ${({ theme }) => theme.lineGrey};
`

export const FlagContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    height: 1.7rem;
`

export const Flag = styled.img`
    height: 1.7rem;
    width: 1.7rem;
    border: 1px solid ${({ theme }) => theme.black};
    border-radius: 50%;
`
